import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import Loading from '@/component/Loading'

function routes() {

    let routsArr = [
        <Route key="/" path="/" component={lazy(() => import('./pages/Home/index'))} exact />,
        <Route key="/app" path="/app" component={lazy(() => import('./pages/App/index'))} exact />,
        <Route key="/device" path="/device" component={lazy(() => import('./pages/Device/index'))} exact />,
        <Route key="/org" path="/org" component={lazy(() => import('./pages/Org/index'))} exact />,
        <Route key="/demo1" path="/demo1" component={lazy(() => import('./pages/Demo1/index'))} exact />,
        <Route key="/demo2" path="/demo2" component={lazy(() => import('./pages/Demo2/index'))} exact />,
        <Route key="/safe" path="/safe" component={lazy(() => import('./pages/Safety/index'))} exact />,
    ]

    return (
        <Switch>
            <Suspense fallback={<Loading height="100vh" />}>
                {routsArr}
            </Suspense>
        </Switch>
    )
}

export default routes;
