import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import Routes from './router';
import App from './App';
import './theme/common.less';
ReactDOM.render(
        <BrowserRouter>
            <App>
                <Routes />
            </App>
        </BrowserRouter>,
  document.getElementById('root')
);

