import React from 'react';
import { ActivityIndicator } from 'zarm';

const Loading = ( (props) => {
    return (
        <div className="flexRow center pageLoading" style={{ height: props.height }}>
            <ActivityIndicator type="spinner" size="lg" />
        </div>
    )
})
export default Loading;
