import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
const App = (props) => {
    useEffect(() => {
        if (document && props.location.pathname != '/home') {
            if (document?.documentElement || document?.body) {
                document.documentElement.scrollTop = document.body.scrollTop = 0;
            }
        }
    }, [props.location.pathname]);
    return (
        <div className="app">
            {props.children}
        </div>
    )
}

export default withRouter(App);
